import React from 'react';
import { styled, theme } from '../../../Theme';
import BaseNotification from './BaseNotification.interface';

type PropType = {
  notification: BaseNotification;
};

function KexNotificationBanner({ notification }: PropType) {
  return (
    <>
      {notification && notification.message ? (
        <NotificationContainer>
          <NotificationBox>{notification.message}</NotificationBox>
        </NotificationContainer>
      ) : (
        ''
      )}
    </>
  );
}

const NotificationContainer = styled.div({
  position: 'fixed',
  top: '-100px',
  left: '0',
  right: '0',
  margin: {
    xy: 'auto',
  },
  animationIterationCount: 1,
  animationDuration: theme.timings.five,
  MozAnimationDirection: 'forward',
  animationName: {
    '0%': {
      transform: 'translateY(-100px)',
    },
    '30%': {
      transform: 'translateY(100px)',
    },
    '50%': {
      transform: 'translateY(100px)',
    },
    '80%': {
      transform: 'translateY(100px)',
    },
    '100%': {
      transform: 'translateY(-100px)',
    },
  },
  maxHeight: '30vh',
  maxWidth: '350px',
});

const NotificationBox = styled.div({
  backgroundColor: theme.secondary,
  color: theme.primary,
  margin: {
    xy: 'auto',
  },
  padding: {
    x: '16px',
    y: '12px',
  },
  boxShadow:
    '0 2px 32px 2px rgba(59, 72, 80, 0.05), 0 0 4px 0 rgba(72, 72, 72, 0.15)',
  textAlign: 'center',
});

export default KexNotificationBanner;
